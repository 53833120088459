.wrapper {
  width: 100%;
  padding-left: var(--cui-sidebar-occupy-start, 0);
  /* // @include ltr-rtl("padding-left", var(--cui-sidebar-occupy-start, 0)); */
  will-change: auto;
  /* // @include transition(padding .15s); */
  background-color: #f4f5f7 !important;
  color: #6c757d !important;
}

.form-switch .form-check-input {
  width: 50px;
  height: 25px;
  vertical-align: middle;
  margin-right: 10px;
}
/* .form-switch .form-check-input {
  width: 50px;
  vertical-align: middle;
  margin-right: 10px;
} */
.mirrorfly-root .mf-section .start-chart p {
  display: none !important;
  margin-bottom: 30px !important;
}
.mirrorfly-root .container .recent-chatlist .recent-chatlist-header .menu-dropdown li:nth-child(4){
  display: none !important;
}